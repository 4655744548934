<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div v-if="loading" class="card-body">
            <div class="text-center">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
          <div v-else class="card-body">
            <b-alert
              v-if="reqError"
              variant="danger"
              class="my-0"
              show
              v-html="reqError"
            />

            <template v-else>
              <div class="row mb-md-2 text-center">
                <div class="col-sm-12 col-md-auto">
                  <div id="tickets-table_length" class="dataTables_length">
                    <router-link
                      v-if="userPermissionsIDs.includes(13)"
                      to="/cities/create"
                      class="btn btn-primary"
                      >{{ $t("city.add") }}</router-link
                    >
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center mr-2">
                      <span class="text-nowrap">{{ $t("quantity") }}:</span>
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="form-control form-control-sm ml-2"
                      ></b-form-select>
                    </label>
                    <label class="d-inline-flex align-items-center">
                      {{ $t("search") }}:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <template v-if="tableData.length > 0">
                <div class="table-responsive mb-0">
                  <b-table
                    :items="tableData"
                    :fields="tableFields"
                    responsive="sm"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    striped
                  >
                    <template #head(name)>
                      {{ $t("table.name") }}
                    </template>

                    <template #head(created_at)>
                      {{ $t("table.created") }}
                    </template>

                    <template #head(updated_at)>
                      {{ $t("table.updated") }}
                    </template>

                    <template #head(action)>
                      {{ $t("table.actions") }}
                    </template>

                    <!-- action -->
                    <template #cell(action)="data">
                      <div class="button-list text-nowrap">
                        <router-link
                          v-if="userPermissionsIDs.includes(12)"
                          :to="{ path: '/cities/view/' + data.item.id }"
                          class="btn btn-primary btn-xs"
                          :title="$t('btn.view')"
                        >
                          <i class="mdi mdi-eye"></i>
                        </router-link>
                        <router-link
                          v-if="userPermissionsIDs.includes(14)"
                          :to="{ path: '/cities/edit/' + data.item.id }"
                          class="btn btn-success btn-xs"
                          :title="$t('btn.edit')"
                        >
                          <i class="mdi mdi-pencil"></i>
                        </router-link>
                        <b-button
                          v-if="userPermissionsIDs.includes(15)"
                          variant="danger"
                          size="xs"
                          @click="confirmDelete(data.item)"
                          :title="$t('btn.delete')"
                        >
                          <i class="mdi mdi-close"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="my-1">
                      {{ displayedRows }} / {{ totalRows }}
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="text-center">
                <div class="mb-3">{{ $t("city.no-cities") }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("sidebar.cities"),
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "sidebar.cities",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.cities",
          active: true,
        },
      ],
      loading: true,
      reqError: false,

      tableData: [],
      tableFields: [],
      totalRows: 30,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      tableFields: [
        {
          key: "id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Created",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Updated",
          sortable: true,
        },
        {
          key: "action",
          class: "cell-action",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["userPermissionsIDs"]),
    displayedRows() {
      const items = this.currentPage * this.perPage;
      return items < this.totalRows ? items : this.totalRows;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loadTableData();
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async loadTableData() {
      this.loading = true;
      this.reqError = false;

      try {
        const response = await axios.get(
          this.$urls.URL_CITIES +
            `?page=${this.currentPage}&per_page=${this.perPage}`
        );
        // console.log("response: ", response);
        this.tableData = response.data.data;
        this.totalRows = response.data.total;
      } catch (error) {
        console.log("loadTableData, error: ", error);
        this.reqError = true;
        if (error.response?.data?.message) {
          this.reqError = `<b>[ERROR: ${error.response.status}]</b> ${error.response?.data?.message}`;
        }
      } finally {
        this.loading = false;
      }
    },
    confirmDelete(item) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('modal.want-delete-city')}: ${item.name}?`, {
          title: this.$t('modal.confirm-delete'),
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: this.$t("btn.yes"),
          cancelTitle: this.$t("btn.no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((sure) => {
          if (sure) {
            this.deleteCity(item);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteCity(city) {
      const formData = new FormData();
      formData.append("_method", "DELETE");
      formData.append("name", city.name);

      axios
        .post(this.$urls.URL_CITIES + "/" + city.id, formData)
        .then(() => {
          this.loadTableData();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },
  },
  watch: {
    currentPage() {
      this.loadTableData();
    },
    perPage() {
      this.loadTableData();
    },
  },
};
</script>
